import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import ListMixin from '~/mixins/list';
import { routeNames } from '~/router';
import PermissionMixin from '~/mixins/permission';
import { toLocalDate } from '#/date-fns-utils';
import PaginatedTable from '~/components/paginated-table.vue';
import ConfirmDialog from '~/components/confirm-dialog.vue';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import { mdiTrashCanOutline, mdiPlusCircleOutline, mdiMagnify, mdiDownload } from '@mdi/js';
const trainersλ = λ.namespaced('trainers');
export default {
    name: 'trainer-list',
    mixins: [ListMixin('trainers'), PermissionMixin],
    components: {
        ConfirmDialog,
        GoBackWrapper,
        PaginatedTable,
    },
    data() {
        return {
            mdiMagnify,
            mdiTrashCanOutline,
            mdiPlusCircleOutline,
            mdiDownload,
            query: {
                sortBy: 'lastName',
                sortDesc: false,
                page: 1,
                itemsPerPage: 10,
                filters: {
                    search: null,
                },
            },
        };
    },
    computed: {
        allTrainers: trainersλ.state('all'),
        totalCount: trainersλ.state('totalCount'),
        headers() {
            return [
                { text: this.$t('First name'), value: 'firstName' },
                { text: this.$t('Last name'), value: 'lastName' },
                { text: this.$t('Certificate Type'), sortable: false },
                { text: this.$t('Status'), value: 'status' },
                { text: this.$t('Processing Date'), sortable: false },
                ...(this.canDeleteTrainers ? [{ text: this.$t('Delete'), sortable: false, }] : []),
            ];
        },
    },
    methods: {
        toLocalDate,
        remove: trainersλ.action('remove'),
        downloadExport: trainersλ.action('downloadExport'),
        openItem(item) {
            if (this.canReadTrainers) {
                this.$router.push({
                    name: routeNames.trainers.edit,
                    params: { id: getId(item) }
                });
            }
        },
        addNewItem() {
            this.$router.push({
                name: routeNames.trainers.create,
            });
        },
        removeTrainer(trainer) {
            this.$refs.deleteDialog
                .open()
                .then(async (doDelete) => {
                if (doDelete) {
                    await this.remove(trainer);
                }
            });
        },
    },
};
